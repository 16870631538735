<template>
<div>
    <section id="home">
    <div class="bg">
      <div class="top-navbar navbar-desktop">
      <nav class="navbar navbar-expand-lg bg-navbar p-2">
          <div class="container-fluid">
             <img class="img-fluid w-20" src="./../assets/logo-senja.jpeg" alt="logo">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
              <ul class="navbar-nav text-white">
                <li class="nav-item">
                  <a class="nav-link" aria-current="page" href="#home">Tentang</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#features">Kategori</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#kurikulum">Kurikulum</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#pricing">Instrukur</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#footer">Kontak Kami</a>
                </li>
              </ul>
            </div>
          </div>
      </nav>

        <div class="container-fluid my-4 bg-mobile">
          <div class="row">
            <div class="col-md-6">
              <h1 class="text-desk">Apa itu Senja Jingga ?</h1>
              <h1 class="text-mobile">Senja Jingga</h1>
                <p class="text-desk justified-text mb-4">Senja Jingga merupakan lembaga kursus mengemudi manual maupun matic yang memberikan pelayanan antar jemput gratis bagi peserta  daerah bandung dan sekitarnya. Senja Jingga memberikan fasilitas yg aman dan nyaman serta dilayani oleh instruktur yg professional dengan harga terjangkau </p>
                <a href="https://wa.me/6285871116348">
                <button class="bg-white btn-contact shadow fw-bold p-3 mb-5 bg-body rounded text-dark px-4 py-2">
                <span>Yuk Pesan</span>
              </button>
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="container-fluid text-mobile">
      <div class="row justify-content-center">
        <div class="col-lg-11 col-md-11 col-sm-9 col-xs-9 my-5">
          <div class="justified-text mb-4">
              <h2 class="text-center">Apa itu Senja Jingga ?</h2>
               <p>Senja Jingga merupakan lembaga kursus mengemudi manual maupun matic yang memberikan pelayanan antar jemput gratis bagi peserta daerah bandung dan sekitarnya. Senja Jingga memberikan fasilitas yg aman dan nyaman serta dilayani oleh instruktur yg professional dengan harga terjangkau </p>
          </div>
        </div>
      </div>
    </div>

    </section>

  <section id="features">
    <div class="container-fluid mt-4">
        <div class="row justify-content-center">
          <div class="col-lg-11 col-md-11 col-sm-9 col-xs-9 my-5">
          
            <div class="text-center mb-4">
              <h2>MOBIL MATIC</h2>
            </div>
            <div class="card-list p-relative">
              <div class="row">
                <div class="col">
                  <div class="card ">
                    <div class="discount">
                        <img class="img-fluid" src="./../assets/discount.png" alt="logo">
                    </div>
                    <img src="./../assets/matic.jpeg" class="img-fluid" alt="matic">
                    <div class="card-body">
                      <h5 class="card-title"></h5>
                      <p class="card-text">PAKET I (PROMO) DURASI 10 JAM</p>
                      <a href="#" class="btn btn-dark text-white">Rp.800.000,-</a>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <img src="./../assets/matic.jpeg" class="img-fluid" alt="matic">
                    <div class="card-body">
                      <h5 class="card-title"></h5>
                      <p class="card-text">PAKET II DURASI 15 JAM</p>
                      <a href="#" class="btn btn-dark text-white">Rp.1.600.000,-</a>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <img src="./../assets/matic.jpeg" class="img-fluid" alt="matic">
                    <div class="card-body">
                      <h5 class="card-title"></h5>
                      <p class="card-text">PAKET III DURASI 20 JAM</p>
                      <a href="#" class="btn btn-dark text-white"> Rp.2.300.000,-</a>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <img src="./../assets/matic.jpeg" class="img-fluid" alt="matic">
                    <div class="card-body">
                      <h5 class="card-title"></h5>
                      <p class="card-text">PAKET IV DURASI 30 JAM</p>
                      <a href="#" class="btn btn-dark text-white">Rp.3.250.000,-</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid mt-4">
        <div class="row justify-content-center">
          <div class="col-lg-11 col-md-11 col-sm-9 col-xs-9 my-5">
            <div class="text-center mb-4">
              <h2>MOBIL MANUAL</h2>
            </div>
            <div class="card-list p-relative">
              <div class="row">
                <div class="col">
                  <div class="card mb-2">
                      <div class="discount">
                        <img class="img-fluid" src="./../assets/discount.png" alt="logo">
                    </div>
                    <img src="./../assets/manual.jpeg" class="img-fluid" alt="...">
                    <div class="card-body">
                      <h5 class="card-title"></h5>
                      <p class="card-text">PAKET I (PROMO) DURASI 10 JAM</p>
                      <a href="#" class="btn btn-dark text-white">Rp.800.000,-</a>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card mb-2">
                    <img src="./../assets/manual.jpeg" class="img-fluid" alt="...">
                    <div class="card-body">
                      <h5 class="card-title"></h5>
                      <p class="card-text">PAKET II DURASI 15 JAM</p>
                      <a href="#" class="btn btn-dark text-white">Rp. 1.325.000,-</a>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card mb-2">
                    <img src="./../assets/manual.jpeg" class="img-fluid" alt="...">
                    <div class="card-body">
                      <h5 class="card-title"></h5>
                      <p class="card-text">PAKET III DURASI 20 JAM</p>
                      <a href="#" class="btn btn-dark text-white">Rp.2.200.000,-</a>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card mb-2">
                    <img src="./../assets/manual.jpeg" class="img-fluid" alt="...">
                    <div class="card-body">
                      <h5 class="card-title"></h5>
                      <p class="card-text">PAKET IV DURASI 30 JAM</p>
                      <a href="#" class="btn btn-dark text-white">Rp.3.150.000,-</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </section>

  

<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-lg-11 col-md-11 col-sm-9 col-xs-9 my-5">
  <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">

  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="./../assets/manual.jpeg" class="d-block w-100" alt="...">
    </div>
    <div class="carousel-item">
      <img src="./../assets/carousel1.jpeg" class="d-block w-100" alt="...">
    </div>
    <div class="carousel-item">
      <img src="./../assets/carousel3.jpeg" class="d-block w-100" alt="...">
    </div>
  </div>
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active thumbnail shadow-lg" aria-current="true" aria-label="Slide 1">
      <img src="./../assets/manual.jpeg" class="d-block w-100" alt="...">
    </button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" class="thumbnail shadow-lg" aria-label="Slide 2">
      <img src="./../assets/carousel1.jpeg" class="d-block w-100" alt="...">
    </button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" class="thumbnail shadow-lg" aria-label="Slide 3">
      <img src="./../assets/carousel3.jpeg" class="d-block w-100" alt="...">
    </button>
  </div>
</div>
    </div>
  </div>
</div>
    


<section id="kurikulum">
 <div class="container-fluid mt-4">
        <div class="row justify-content-center">
          <div class="col-lg-11 col-md-11 col-sm-9 col-xs-9 my-5">
          
            <div class="text-center mb-5">
              <h2 class="border-bottom border-5 border-dark d-inline-block">KURIKULUM KURSUS MENGEMUDI SENJA JINGGA</h2>
            </div>
          <div class="row">
            <div class="col-md-6">
              <div class="border border-3 border-dark">
                <div class="text-center bg-dark text-white">
                  <h3 class="border border-3 border-dark">MANUAL</h3>
                </div>
                <div class="content-price mb-3">
                  <div class="p-2 text-center border-bottom border-top border-3 border-dark">
                    <p class="fw-bold"> PAKET I (PROMO) DURASI 10 JAM</p>
                    <p class="fw-bold" >HARGA: <span class="text-discount">Rp.1000.000</span>  = Rp.850.000,- </p>
                  </div>
                  <div class="list-price my-2">
                    <ul>
                      <li>Pertemuan 1 (3 Jam) -> Penjelasan, Simulasi, Praktek</li>
                      <li>Pertemuan 2 (3 Jam) -> Praktek, Parkir Serong</li>
                      <li>Pertemuan 3 (4 Jam) -> Praktek, Evaluasi</li>
                    </ul>
                  </div>
                </div>
                 <div class="content-price mb-3">
                  <div class="p-2 text-center border-bottom border-top border-3 border-dark">
                    <p class="fw-bold"> PAKET II DURASI 15 JAM</p>
                    <p class="fw-bold" >HARGA: Rp.1.500.000,- </p>
                  </div>
                  <div class="list-price my-2">
                    <ul>
                      <li>Pertemuan 1 (3 Jam) -> Penjelasan, Simulasi, Praktek</li>
                      <li>Pertemuan 2 (3 Jam) -> Praktek, Parkir Serong</li>
                      <li>Pertemuan 3 (3 Jam) -> Praktek, Parkir Paralel</li>
                      <li>Pertemuan 4 (3 Jam) -> Praktek, Evaluasi</li>
                      <li>Pertemuan 5 (3 Jam) -> Praktek, Evaluasi</li>
                    </ul>
                  </div>
                </div>
                  <div class="content-price mb-3">
                  <div class="p-2 text-center border-bottom border-top border-3 border-dark">
                    <p class="fw-bold"> PAKET III DURASI 20 JAM</p>
                    <p class="fw-bold" >HARGA: Rp.2.200.000,-</p>
                  </div>
                  <div class="list-price my-2">
                    <ul>
                      <li>Pertemuan 1 (3 Jam) -> Penjelasan, Simulasi, Praktek</li>
                      <li>Pertemuan 2 (3 Jam) -> Praktek, Parkir Serong</li>
                      <li>Pertemuan 3 (3 Jam) -> Praktek, Parkir Paralel</li>
                      <li>Pertemuan 4 (3 Jam) -> Praktek, Parkir Seri</li>
                      <li>Pertemuan 5 (4 Jam) -> Praktek, Evaluasi</li>
                      <li>Pertemuan 6 (4 Jam) -> Praktek, Evaluasi</li>
                    </ul>
                  </div>
                </div>
                 <div class="content-price mb-3">
                  <div class="p-2 text-center border-bottom border-top border-3 border-dark">
                    <p class="fw-bold"> PAKET IV DURASI 30 JAM</p>
                    <p class="fw-bold" > HARGA: Rp.3.150.000,-</p>
                  </div>
                  <div class="list-price my-2">
                    <ul>
                      <li>Pertemuan 1 (3 Jam) -> Penjelasan, Simulasi, Praktek</li>
                      <li>Pertemuan 2 (3 Jam) -> Praktek, Parkir Serong</li>
                      <li>Pertemuan 3 (4 Jam) -> Praktek, Parkir Paralel</li>
                      <li>Pertemuan 4 (4 Jam) -> Praktek, Parkir Seri</li>
                      <li>Pertemuan 5 (4 Jam) -> Praktek, Evaluasi</li>
                      <li>Pertemuan 6 (4 Jam) -> Praktek, Evaluasi</li>
                      <li>Pertemuan 7 (4 Jam) -> Praktek, Evaluasi</li>
                      <li>Pertemuan 8 (4 Jam) -> Praktek, Evaluasi</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
             <div class="col-md-6">
              <div class="border border-3 border-dark">
                <div class="text-center bg-dark text-white">
                  <h3 class="border border-3 border-dark">MATIC</h3>
                </div>
                <div class="content-price mb-3">
                  <div class="p-2 text-center border-bottom border-top border-3 border-dark">
                    <p class="fw-bold"> PAKET I (PROMO) DURASI 10 JAM</p>
                    <p class="fw-bold" > HARGA : <span class="text-discount">Rp.1.100.000</span>  = Rp.800.000,- </p>
                  </div>
                  <div class="list-price my-2">
                    <ul>
                      <li>Pertemuan 1 (3 Jam) -> Penjelasan, Simulasi, Praktek</li>
                      <li>Pertemuan 2 (3 Jam) -> Praktek, Parkir Serong</li>
                      <li>Pertemuan 3 (4 Jam) -> Praktek, Evaluasi</li>
                    </ul>
                  </div>
                </div>
                 <div class="content-price mb-3">
                  <div class="p-2 text-center border-bottom border-top border-3 border-dark">
                    <p class="fw-bold">PAKET II DURASI 15 JAM</p>
                    <p class="fw-bold" >HARGA: Rp.1.600.000,- </p>
                  </div>
                  <div class="list-price my-2">
                    <ul>
                      <li>Pertemuan 1 (3 Jam) -> Penjelasan, Simulasi, Praktek</li>
                      <li>Pertemuan 2 (3 Jam) -> Praktek, Parkir Serong</li>
                      <li>Pertemuan 3 (3 Jam) -> Praktek, Parkir Paralel</li>
                      <li>Pertemuan 4 (3 Jam) -> Praktek, Evaluasi</li>
                      <li>Pertemuan 5 (3 Jam) -> Praktek, Evaluasi</li>
                    </ul>
                  </div>
                </div>
                  <div class="content-price mb-3">
                  <div class="p-2 text-center border-bottom border-top border-3 border-dark">
                    <p class="fw-bold"> PAKET III DURASI 20 JAM</p>
                    <p class="fw-bold" >HARGA: Rp.2.300.000,-</p>
                  </div>
                  <div class="list-price my-2">
                    <ul>
                      <li>Pertemuan 1 (3 Jam) -> Penjelasan, Simulasi, Praktek</li>
                      <li>Pertemuan 2 (3 Jam) -> Praktek, Parkir Serong</li>
                      <li>Pertemuan 3 (3 Jam) -> Praktek, Parkir Paralel</li>
                      <li>Pertemuan 4 (3 Jam) -> Praktek, Parkir Seri</li>
                      <li>Pertemuan 5 (4 Jam) -> Praktek, Evaluasi</li>
                      <li>Pertemuan 6 (4 Jam) -> Praktek, Evaluasi</li>
                    </ul>
                  </div>
                </div>
                 <div class="content-price mb-3">
                  <div class="p-2 text-center border-bottom border-top border-3 border-dark">
                    <p class="fw-bold"> PAKET IV DURASI 30 JAM</p>
                    <p class="fw-bold" > HARGA:  Rp.3.250.000,-</p>
                  </div>
                  <div class="list-price my-2">
                    <ul>
                      <li>Pertemuan 1 (3 Jam) -> Penjelasan, Simulasi, Praktek</li>
                      <li>Pertemuan 2 (3 Jam) -> Praktek, Parkir Serong</li>
                      <li>Pertemuan 3 (4 Jam) -> Praktek, Parkir Paralel</li>
                      <li>Pertemuan 4 (4 Jam) -> Praktek, Parkir Seri</li>
                      <li>Pertemuan 5 (4 Jam) -> Praktek, Evaluasi</li>
                      <li>Pertemuan 6 (4 Jam) -> Praktek, Evaluasi</li>
                      <li>Pertemuan 7 (4 Jam) -> Praktek, Evaluasi</li>
                      <li>Pertemuan 8 (4 Jam) -> Praktek, Evaluasi</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
</section>
   

  <section id="pricing">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-lg-11 col-md-11 col-sm-9 col-xs-9 my-5">
            <div class="text-center mb-4">
              <h2 class="border-bottom border-5 border-dark d-inline-block">INSTRUKTUR</h2>
            </div>
            <div class="card-list">
              <div class="row">
                <div class="col">
                  <div class="card b-none">
                    <img src="https://cdn.pixabay.com/photo/2016/01/18/05/51/worker-1146077_1280.jpg" class="img-fluid" alt="...">
                    
                  </div>
                </div>
                <div class="col">
                  <div class="card b-none">
                    <img src="./../assets/instruktur-1.jpeg" class="img-fluid" alt="...">
                    
                  </div>
                </div>
                <div class="col">
                  <div class="card b-none">
                    <img src="https://cdn.pixabay.com/photo/2016/01/18/05/51/worker-1146077_1280.jpg" class="img-fluid" alt="...">
                    
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center mt-2">
              <h4>Instruktur berpengalaman dan bersertifikat</h4>
            </div>
          </div>
        </div>
      </div>
  </section>

    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-11 col-md-11 col-sm-9 col-xs-9 mt-4 mb-2">
          <div class="text-center mb-4">
             <h2 class="mb-4 border-bottom border-5 border-dark d-inline-block">ALAMAT</h2>
             <h3 class="mb-4">JL. Adiwarta No.1 Lembang, Kec.Lembang, Kabupaten Bandung</h3>
               <button @click="shareLocation" class="bg-white btn-contact shadow fw-bold p-3 mb-5 bg-body rounded text-dark px-4 py-2">
                <span>BUKA MAP</span>
              </button>
          </div>
        </div>
      </div>
    </div>

  <section id="footer">
  <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-lg-11 col-md-11 col-sm-9 col-xs-9 mt-4 ">
            <div class="text-center mb-4 ">
              <h2 class="border-bottom border-5 border-dark d-inline-block m-auto ">KONTAK KAMI</h2>
            </div>
          </div>
        </div>
  </div>
<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-lg-11 col-md-11 col-sm-9 col-xs-9">
      <div class="text-center">
 <div class="">
        <a class="text-decoration-none text-dark " href="https://wa.me/085871116349"><img class="img-fluid" src="./../assets/whatsapp-color.png" alt="logo"> <span class="h4">0858 7111 6348</span></a>
    </div>
     <div class="">
        <a class="text-decoration-none text-dark " href="#"><img class="img-fluid contact-dekstop" src="./../assets/bca.png" alt="logo"> <span class="h4 fs-14">1370301707 a/n Yosi Siti Solihah</span></a>
    </div>
      </div>
    </div>
   
  </div>
</div>
      <div class="social-mobile mt-4 mb-4 ps-2">
        <div class="container-fluid">
         <div class="d-flex justify-content-between">
           <a href="#"><img class="img-fluid w-75" src="./../assets/facebook.png" alt="logo"></a>
        <a href="https://www.tiktok.com/@senjajinggamengemudi?_t=8gIzRqajUOo&_r=1"><img class="img-fluid w-75" src="./../assets/tiktok.png" alt="logo"></a>
       <a href="https://wa.me/6285871116348"><img class="img-fluid w-75" src="./../assets/whatsapp.png" alt="logo"></a>
       <a href="https://instagram.com/senjajinggakursusmengemudi?igshid=NjIwNzIyMDk2Mg=="><img class="img-fluid w-75" src="./../assets/instagram.png" alt="logo"></a>
        </div>
        </div>
      </div>

    <div class="wrapper">
      <ul class="social_icons justify-content-center">
        <li><a href="#"><img class="img-fluid" src="./../assets/facebook.png" alt="logo"></a></li>
        <li><a href="https://www.tiktok.com/@senjajinggamengemudi?_t=8gIzRqajUOo&_r=1"><img class="img-fluid" src="./../assets/tiktok.png" alt="logo"></a></li>
       <li><a href="https://wa.me/6285871116348"><img class="img-fluid" src="./../assets/whatsapp.png" alt="logo"></a></li>
       <li><a href="https://instagram.com/senjajinggakursusmengemudi?igshid=NjIwNzIyMDk2Mg=="><img class="img-fluid" src="./../assets/instagram.png" alt="logo"></a></li>

      </ul>
    </div>
  </section>

  <footer>
    <div class="d-flex justify-content-center bg-dark text-white p-2">
      <span>@Copyright Senja Jingga Rental Bandung 2023</span>
    </div>
  </footer>
</div>
</template>

<script>
import './navbar';

export default {
  data() {
    return {
      carouselItems: [
        { title: 'Slide 1', image: './../assets/manual.jpeg' },
        { title: 'Slide 2', image: 'https://otodriver.com/image/load/472/270/gallery/img-20230411-2340463233.jpg' },
        { title: 'Slide 3', image: 'https://us.123rf.com/450wm/greens87/greens871711/greens87171100061/89982214-sagoma-di-auto-con-le-luci-accese-su-uno-sfondo-nero-illustrazione-laterale-vector.jpg?ver=6' },
      ],
    };
  },
    methods: {
    shareLocation() {
      const latitude = -6.81778;
      const longitude = 107.62257;
      const shareLink = `https://www.google.com/maps/place/${latitude},${longitude}`;
      window.open(shareLink, '_blank');
    },
  },
};
</script>






