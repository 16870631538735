<template>

  <HomePage/>
</template>

<script>
import HomePage from './components/HomePage.vue';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './assets/css/style.css';





export default {
  name: 'App',
  components: {
    HomePage
  }
}
</script>


