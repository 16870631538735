
  document.addEventListener('DOMContentLoaded', function () {
    // Add smooth scrolling to all links with the "nav-link" class
    const navLinks = document.querySelectorAll('.nav-link');
    
    navLinks.forEach(function (link) {
      link.addEventListener('click', function (event) {
        event.preventDefault();
        const targetId = this.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);
        
        if (targetElement) {
          window.scrollTo({
            top: targetElement.offsetTop - 50, // Adjust the offset if needed
            behavior: 'smooth',
          });
        }
      });
    });
  });
